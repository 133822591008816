@font-face {
  font-family: Gotham;
  src: url('../assets/GothamSSm-Medium.otf') format('opentype');
}
body {
  margin: 0;
  overflow-x: auto;
  height: 100vh;
}

.cta-button {
  background: radial-gradient(101.83% 75.64% at 0% 75.64%, #50AAFF 0%, #0083FF 100%);
  color: white;
  font-family: Gotham, sans-serif;
  font-size: 1.25rem;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    color: rgba(255,255,255,0.75);
    text-decoration: none;
    transform: translate(-0.25rem, -0.25rem);
    filter: drop-shadow(0.25rem 0.25rem 4px rgba(0,0,0,0.25));
  }
}

#logo {
  width: 100%;
}

#tagline {
  width: 100%;
}
@media screen and (orientation:landscape) 
and (max-device-height: 600px) {
  #logo {
    height: 3rem;
    width: unset;
  }

  #tagline {
    height: 8rem;
    width: unset;
  }
}

